import axios from "axios";
// import { useNavigate } from "react-router-dom";

const authAxios = axios.create({

    // qa
    // baseURL: 'https://qa.quicksurveys.io', 
    baseURL: 'https://quick-survey-server.quicksurveys.io/',
    // baseURL: 'http://localhost:4000',

    headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('ps_token'))}`,
    }
});

authAxios.interceptors.response.use((config) => {

    return config;

}, (error) => {

    let { response: { data: { status } } } = error

    if (status == 401) {
        localStorage.clear()
        localStorage.setItem("block","You are blocked by Super Admin, Please contact with  Super Admin for more details.")
    }
    return Promise.reject(error);
});





export default authAxios;