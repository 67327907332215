import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Autocomplete, Avatar, Badge, Box, Button, Card, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import LoadingUI from '../../common/LoadingUI';
import SnackUI from '../../common/SnackUI';
import { addProfileDetailsAction } from '../../redux/profile/addProfile';
import { getProfileAction } from '../../redux/profile/getPublisherProfile';
import { uplodImageAction } from '../../redux/profile/uplodImage';





const publisherProfileSchema = yup.object({
    app_name: yup.string().required('App name is required.'),
    publisher_email: yup.string().required('Email is required.'),
    publisher_bio: yup.string().required('Bio is required.'),
    currancy_name: yup.string().required('currancy is required.'),
    tax_id: yup.string().required('Tax id is required.'),
    // exchange_rate: yup.object({
    //     against_INR: yup.string().required('INR exchange rate is required.'),
    //     // against_USD: yup.number().positive().required('USD exchange rate is required.'),
    // }),
    address: yup.object({
        street_name: yup.string().required('Street name is required.'),
        street_no: yup.string().required('Street no is required.'),
        city: yup.string().required('City is required.'),
        country: yup.string().required('Country is required.'),
    })
}).required();

export default function AddPublisher() {
    // state
    const dispatch = useDispatch()
    const getProfileSelector = useSelector(state => state.getProfile)
    const { data, loading } = getProfileSelector

    const addProfileDetailsSelector = useSelector(state => state.addProfileDetails)
    const { status, message } = addProfileDetailsSelector

    const { register, setError, reset, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(publisherProfileSchema), defaultValues: {} });

    const [platform, setPlatForm] = useState([])
    const [snack, setSnack] = useState(false)
    const [avatar, setAvatar] = useState();
    const [secretKeyVisible, setSecretKeyVisible] = useState(false);
    const [appKeyVisible, setAppKeyVisible] = useState(false);
    const[conversion,setConversion] = useState(data?.exchange_rate?.against_INR)




    // fn
    const handlePlatform = (e, newVal) => {
        setPlatForm(newVal)
    }


    const handleAddProfile = async (data) => {

        data.exchange_rate.against_INR = conversion

        if (!platform.length) {
            return setError('platform', { type: 'custom', message: 'Atleast one platform is need to select' });
        }
        if (!avatar) {
            return setError('avatarError', { type: 'custom', message: 'Logo is required' });
        }
        let addProfileDetails = await dispatch(addProfileDetailsAction({ profile_img: avatar, platform: platform, ...data }))
         
        if (addProfileDetails?.payload?.status == 200) {
            navigate("/profile")
        }
        setSnack(true)
    }

    const handleavatar = async (e) => {

        const formData = new FormData();

        formData.set("file", e.target.files[0]);

        let uploadedImg = await dispatch(uplodImageAction(formData))
        setAvatar(uploadedImg?.payload?.data?.url)

    }



    // useEffect
    useEffect(() => {
        dispatch(getProfileAction())
    }, [dispatch])

    useEffect(() => {

        setPlatForm(data?.platform)
        setAvatar(data?.profile_img)
        let defaultValues = {}
        defaultValues.publisher_name = data?.publisher_name
        defaultValues.app_name = data?.app_name
        defaultValues.tax_id = data?.tax_id
        defaultValues.publisher_email = data?.publisher_email
        defaultValues.publisher_bio = data?.publisher_bio
        defaultValues.currancy_name = data?.currancy_name
        defaultValues.exchange_rate = {
            against_INR: data?.exchange_rate?.against_INR,
            // against_USD: data?.exchange_rate?.against_USD,
        }
        defaultValues.address = {
            street_name: data?.address?.street_name,
            street_no: data?.address?.street_no,
            city: data?.address?.city,
            country: data?.address?.country
        }
        
        reset(defaultValues)

    }, [dispatch, data])

useEffect(()=>{
    setConversion(data?.exchange_rate?.against_INR)
},[])

    const navigate = useNavigate()
    return (
        <>
            <Card sx={{ p: 3 }}>
                <form encType="multipart/form-data" onSubmit={handleSubmit(handleAddProfile)}>
                    {loading ? <LoadingUI />
                        : <>
                            <Stack spacing={4} >
                                <Stack>
                                    <Typography sx={{ my: 1 }} variant='h5'>Profile</Typography>
                                    <Typography variant='subtitle2'>This information will let us know more about you.</Typography>
                                </Stack>

                                <input accept="image/*" name="avatar" id="upload-avatar-pic" type="file" hidden onChange={handleavatar} />
                                <label htmlFor="upload-avatar-pic">

                                    <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<EditIcon />} >
                                        <Avatar src={avatar} sx={{ width: 60, height: 60 }} />
                                    </Badge>

                                </label>
                                {errors?.avatarError?.message && <Typography variant='subtitle1' color='error'>{errors?.avatarError?.message}</Typography>}

                                <FormControl variant="outlined">
                                    <InputLabel>App Key</InputLabel>
                                    <OutlinedInput disabled defaultValue={data?.app_key} fullWidth label="App Key"
                                        type={appKeyVisible ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setAppKeyVisible(!appKeyVisible)}
                                                    edge="end"
                                                >
                                                    {appKeyVisible ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel>Secret Key</InputLabel>
                                    <OutlinedInput disabled defaultValue={data?.secret_key} fullWidth label="Secret Key"
                                        type={secretKeyVisible ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setSecretKeyVisible(!secretKeyVisible)}
                                                    edge="end"
                                                >
                                                    {secretKeyVisible ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                </FormControl>


                                <TextField {...register('app_name')} defaultValue={data?.app_name} helperText={errors?.app_name?.message} error={errors?.app_name?.message} fullWidth label="App Name" variant="outlined" />
                                <TextField disabled {...register('publisher_email')} defaultValue={data?.publisher_email} helperText={errors?.publisher_email?.message} error={errors?.publisher_email?.message} fullWidth label="Publisher Email" variant="outlined" />
                                <TextField {...register('publisher_bio')} defaultValue={data?.publisher_bio} helperText={errors?.publisher_bio?.message} error={errors?.publisher_bio?.message} fullWidth multiline label="Publisher Bio" variant="outlined" />


                                <TextField {...register('tax_id')} defaultValue={data?.tax_id} helperText={errors?.tax_id?.message} error={errors?.tax_id?.message} fullWidth multiline label="Tax Id" variant="outlined" />

                                <TextField {...register('currancy_name')} defaultValue={data?.currancy_name} helperText={errors?.currancy_name?.message} error={errors?.currancy_name?.message} fullWidth label="Currency Name" variant="outlined" />
                                <Stack spacing={2}>
                                    <Typography>Exchange Rate</Typography>
                                    <Grid container rowSpacing={2}>
                                        {/* <Grid item xs={12}>
                                            <Stack direction={'row'} spacing={2} alignItems='center'>
                                                <TextField disabled label="1$" variant="outlined" />
                                                <Typography>=</Typography>
                                                <TextField type="number"  inputProps={{ min: 0 }} {...register('exchange_rate.against_USD')} defaultValue={data?.exchange_rate?.against_USD} helperText={errors?.exchange_rate?.against_USD?.message} error={errors?.exchange_rate?.against_USD?.message} fullWidth variant="outlined" />
                                            </Stack>
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <Stack direction={'row'} spacing={2} alignItems='center'>
                                                <TextField disabled label="1₹" variant="outlined" />
                                                <Typography>=</Typography>
                                                <TextField type="text" onChange={(e)=>setConversion(Number(e.target.value))}    defaultValue={conversion} helperText={!conversion && "Exchange Is Required"} error={!conversion && "Exchange Is Required"} fullWidth variant="outlined" />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>



                                <Stack sx={{ width: '100%' }}>
                                    {data && data?.platform
                                        ? <><Autocomplete multiple defaultValue={data?.platform} onChange={handlePlatform} options={['Android', 'IOS', 'Web']} getOptionLabel={(option) => option} renderInput={(params) => (<TextField  {...params} label="Platform" placeholder="Select Platforms" />)} />
                                            {errors?.platform?.message && <Typography variant='error'>{errors?.platform?.message}</Typography>}
                                        </>
                                        : null}
                                </Stack>

                                <TextField {...register('address.street_no')} defaultValue={data?.address?.street_no} helperText={errors?.address?.street_no?.message} error={errors?.address?.street_no?.message} fullWidth label="Street Number" variant="outlined" />
                                <TextField {...register('address.street_name')} defaultValue={data?.address?.street_name} helperText={errors?.address?.street_name?.message} error={errors?.address?.street_name?.message} fullWidth label="Street Name" variant="outlined" />
                                <TextField {...register('address.city')} defaultValue={data?.address?.city} helperText={errors?.address?.city?.message} error={errors?.address?.city?.message} fullWidth label="City" variant="outlined" />
                                <TextField {...register('address.country')} defaultValue={data?.address?.country} helperText={errors?.address?.country?.message} error={errors?.address?.country?.message} fullWidth label="Country" variant="outlined" />
                            </Stack>
                            <Box sx={{ my: 2 }}>
                                <Button variant='contained' type='submit'>Add Profile Details</Button>
                            </Box></>
                    }
                </form>
            </Card>
            <SnackUI state={snack} setState={setSnack} status={status} message={message} />
        </>

    )
}

