import {
  Alert,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import SnackUI from "../../common/SnackUI";

export default function SurveyDetails() {
  // state
  const getProfileSelector = useSelector((state) => state.getProfile);
  const { data } = getProfileSelector;

  let [surveyUrl, setSurveyUrl] = useState("");
  const [platform, setPlatform] = useState(null);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const[showAlert,setShowAlert] = useState(false)

  // fn
  const handleCopyHash = () => {
    setSnack(true);
    setSnackMessage("Survey Url with Selected Platform copied");
  };

  useEffect(() => {
    setSurveyUrl(
      `https://quick-survey-pwa.quicksurveys.io/?platform=${platform}&publisher_id=${data?._id}`
    );
  }, [platform]);
  useEffect(()=>{
    if(data?.platform?.length > 0){
      setShowAlert(false)

    } else{
      setShowAlert(true)
    }
  },[data])

  return (
    <>
      {showAlert && (
        <Alert severity="warning">
          You need to complete your profile to see your integration link
        </Alert>
      )}

      <br />

      <Card sx={{ p: 2 }}>
        <Stack gap={"10px"} direction={"row"} justifyContent={"space-between"} sx={{
          bgcolor:"whitesmoke",
          paddingBlock:"20px",
          paddingInline:"10px",
          borderRadius:"10px"
        }}>
          <Typography color={"red"}>Refresh the page if you have completed your profile to see your integration link.</Typography>
          <Button onClick={()=>window.location.reload()} sx={{
            width:'100px'
          }} variant="contained">Refresh</Button>
        </Stack>
        <br />
        <Grid container>
          <Grid item xs={12}>
            <Typography color="primary">Survey Web Url</Typography>

            <Box sx={{ width: "100%", wordWrap: "break-word", height: "auto" }}>
              {platform ? <Typography>{surveyUrl}</Typography> : "--"}
            </Box>

            <Box>
              <FormControl>
                <FormLabel sx={{ mt: 1 }}>Platform</FormLabel>

                <RadioGroup
                  value={platform}
                  onChange={(event) => {
                    setPlatform(event.target.value);
                  }}
                >
                  {data &&
                    data?.platform &&
                    data?.platform.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={item}
                          control={<Radio />}
                          label={item}
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
            </Box>

            <CopyToClipboard text={surveyUrl} onCopy={handleCopyHash}>
              <Button sx={{ my: 1 }} disabled={!platform} variant="contained">
                Copy Survey Url
              </Button>
            </CopyToClipboard>
          </Grid>
        </Grid>
      </Card>

      <SnackUI
        state={snack}
        setState={setSnack}
        status={200}
        message={snackMessage}
      />
    </>
  );
}
